// ==============================
// Override Variables
// 覆盖变量
// ==============================


// Color of the text
//$global-font-color: #161209 !default;
// github字体颜色
$global-font-color: #1f2328 !default;
// 叶夕青夕博客字体颜色
//$global-font-color: #343a40 !default;

$global-link-color: #1f2328 !default;